import React from "react"
import { navigate } from "gatsby"
import Layout from '../components/template/Layout'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, InputAdornment } from '@material-ui/core'
import { Person, Mail, Phone, Subject, Message } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  right: {
    textAlign: 'right'
  }
}));


const check = (event, regex, helperText) => {
  if(!regex.test(event.target.value)) {
    event.target.error = true;
    event.target.helperText = helperText;
  }
}

const checkEmail = (event) => {
  check(event, /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Veuillez entrer un e-mail correct");
}

const checkPhone = (event) => {
  check(event, /^\+?[0-9]{1,3} *(\([0-9]+\))?[0-9 ]+$/, "Veuillez entrer un numéro de téléphone correct");
}

const checkMessage = (event) => {
  check(event, /^.{5,}$/, "Veuillez entrer un message non vide");
}

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

function handleSubmit(event) {
  // Prevent the default onSubmit behavior
  event.preventDefault();
  // POST the encoded form with the content-type header that's required for a text submission
  // Note that the header will be different for POSTing a file
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({ 
      "form-name": event.target.getAttribute("name"), 
      "name": event.target[0].value,
      "email": event.target[1].value,
      "phone": event.target[2].value,
      "subject": event.target[4].value,
      "message": event.target[5].value
    })
  })
    // On success, redirect to the custom success page using Gatsby's `navigate` helper function
    .then(() => navigate("/contact-ok/"))
    // On error, show the error in an alert
    .catch(error => alert(error));
};

export default function Contact() {
  const classes = useStyles();

  return (
    <Layout title="Nous contacter" description="Contact" type="Contact" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <form name="contact" action="/contact-ok/" /*onSubmit={sendmail}*/ onSubmit={handleSubmit} method="post" /*data-netlify-recaptcha="true"*/ data-netlify="true" data-netlify-honeypot="address">
          <Grid container>
            <Grid xs={12} sm={6} lg={4} className={classes.control}>
              <TextField fullWidth="true" id="name" name="name" label="Votre nom" InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}/>
            </Grid>
            <Grid xs={12} sm={6} lg={4} className={classes.control}>
              <TextField fullWidth="true" id="email" name="email" type="email" label="Votre e-mail" required onChange={checkEmail} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}/>
            </Grid>
            <Grid xs={12} sm={6} lg={4} className={classes.control}>
              <TextField fullWidth="true" id="phone" name="phone" type="tel" label="Votre téléphone" required onChange={checkPhone} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}/>
              <input type="hidden" id="address" name="address"/>
            </Grid>
            <Grid xs={12} className={classes.control}>
              <TextField fullWidth="true" id="subject" name="subject" label="Sujet de votre demande" value="Demande de contact depuis chateaubriant.jcef.asso.fr" InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Subject />
                  </InputAdornment>
                ),
              }}/>
            </Grid>
            <Grid xs={12} className={classes.control}>
              <TextField fullWidth="true" id="message" name="message" label="Votre Message" required multiline onChange={checkMessage} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Message />
                  </InputAdornment>
                ),
              }}/>
            </Grid>
            <Grid xs={6} className={classes.right}>
            </Grid>
            <Grid xs={6} className={classes.right}>
              <input type="submit" value="Valider"/>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  );
}

